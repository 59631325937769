<template>
  <el-container>
    <el-main>
      <!--      <div style="padding: 20px">-->
      <el-tabs value="first" style="outline: 0;">
        <el-tab-pane label="新增资源" name="first">
          <el-form ref="resForm" :rules="rules" :model="resForm" label-width="100px" size="small">
            <!--<el-form-item label="上级菜单">
              <el-input v-model="resForm.resourceName" placeholder="上级菜单"></el-input>
            </el-form-item>-->
            <el-form-item label="上级菜单" prop="tempPid">
              <el-cascader
                  v-model="resForm.tempPid"
                  :options="options"
                  @change="handleChange"
                  :props="{ checkStrictly: true }"
                  clearable></el-cascader>
            </el-form-item>
            <el-form-item label="资源类型" prop="resourceType">
              <el-select v-model="resForm.resourceType" placeholder="资源类型" style="width: 100%">
                <el-option label="菜单" value="0"></el-option>
                <el-option label="链接" value="1"></el-option>
                <el-option label="按钮" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资源名称" prop="resourceName">
              <el-input placeholder="资源名称" v-model="resForm.resourceName" style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item label="资源编号" prop="resourceCode">
              <el-input placeholder="资源编号" v-model="resForm.resourceCode" style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item label="URL" prop="resourceUrl">
              <el-input placeholder="URL" v-model="resForm.resourceUrl" style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item label="target">
              <el-input placeholder="target" v-model="resForm.target" style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item label="redirect">
              <el-input placeholder="redirect" v-model="resForm.redirect" style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item label="排序">
              <el-input placeholder="排序" v-model="resForm.sort" style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item label="icon" prop="icon">
              <el-input placeholder="icon" v-model="resForm.icon" style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item label="是否叶子节点">
              <el-switch v-model="resForm.isLeafNode" active-value="1" inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="是否可见">
              <el-switch v-model="resForm.isShow" active-value="1" inactive-value="0"></el-switch>
            </el-form-item>

            <el-form-item label="描述" prop="resourceDesc">
              <el-input type="textarea" v-model="resForm.resourceDesc"></el-input>
            </el-form-item>
            <!--              <el-form-item>-->
            <!--                <el-button type="primary" @click="onSubmit('resForm')">立即创建</el-button>-->
            <!--                <el-button @click="drawer.status = false;">取消</el-button>-->
            <!--              </el-form-item>-->
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <!--      </div>-->
    </el-main>
    <el-footer>
      <el-button type="primary" size="small" @click="onSubmit('resForm')">立即创建</el-button>
      <el-button size="small" @click="drawer.status = false">取消</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import request from "@/utils/request";

export default {
  props: {
    drawer: Object,
    findByPage: Function
  },
  data() {
    return {
      resForm: {
        resourcePName: '',
        resourcePid: '',
        resourceType: '',
        resourceName: '',
        resourceCode: '',
        resourceUrl: '',
        target: '',
        sort: '',
        resourceLevel: '',
        isLeafNode: '1',
        icon: '',
        isShow: '1',
        resourceDesc: '',
        tempPid: '0',
        casCaderId: '',
        redirect: ''
      },
      rules: {
        tempPid: [
          {required: true, message: '请选择上级菜单', trigger: 'change'}
        ],
        resourceType: [
          {required: true, message: '请选择资源类型', trigger: 'change'}
        ],
        resourceName: [
          {required: true, message: '请填写资源名称', trigger: 'blur'}
        ],
        resourceCode: [
          {required: true, message: '请填写资源编号', trigger: 'blur'}
        ],
        resourceUrl: [
          {required: true, message: '请填写资源URL', trigger: 'blur'}
        ],
        icon: [
          {required: false, message: '请填写icon信息', trigger: 'blur'}
        ],
        resourceDesc: [
          {required: true, message: '请填写资源描述信息', trigger: 'blur'}
        ]
      },
      options: [],
      activeName: 'first'
    }
  },
  methods: {
    onSubmit(formName) {
      this.resForm.resourcePid === '' ? this.resForm.resourcePid = '0' : this.resForm.resourcePid

      this.$refs[formName].validate((valid) => {
        if (valid) {
          return request({
            method: 'post',
            url: '/masterdata/resource/createResource',
            contentType: 'application/json',
            data: this.resForm,
            baseURL: 'http://127.0.0.1:8093'
          }).then((response) => {
            // eslint-disable-next-line no-empty
            if (response.data.id !== null) {
              this.$message({
                showClose: true,
                message: '创建成功',
                type: 'success',
              });

              this.$refs[formName].resetFields();
              this.drawer.status = false
              this.findByPage()
            }
          })
        }
      })
    },
    handleChange(value) {
      this.resForm.casCaderId = value + ""

      let val = value[value.length - 1]
      this.resForm.resourcePid = val
    },
    initMenu() {

      return request({
        url: `/masterdata/resource/findByTree`,
        method: 'post',
        baseURL: 'http://127.0.0.1:8093',
        contentType: 'application/json',
        data: {}
      }).then((response) => {

        const options = []
        response.data.forEach(obj => {
          const father = {label: obj.resourceName, value: obj.id}

          if (obj.children) {
            father.children = []
            obj.children.forEach(ch => {
              const children = {label: ch.resourceName, value: ch.id}
              father.children.push(children)
            })
          }
          options.push(father)
        })

        this.options = options
      })
    },
    childrenNode() {

    }
  }, mounted() {
    this.initMenu()
  }
}
</script>

<style>
/deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.el-footer {
  color: #333;
  text-align: right;
}

.el-main {
  color: #333;
}

.el-container {
  height: 100% !important;
}

.el-drawer__body {
  overflow: auto;
}
</style>
